import React from 'react'
import team1 from '../Assets/team1.jpg';
import team2 from '../Assets/team2.jpg';
import team3 from '../Assets/team3.jpg';
import team4 from '../Assets/team4.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';


export default function Team({teamRef}) {
    return (
        <div className=' bg-customBlack' style={{ Height: "90vh" }} ref={teamRef}>
            <div className="py-10">
                <div className="">
                    <div className="flex justify-center">
                        <h1 className="h-4 w-4 border-l-4 border-t-4 border-colorfaq"></h1>
                        <h1 className="text-4xl text-colorfaq">Our Team</h1>
                        <h1 className="mt-7 h-4 w-4 border-b-4 border-r-4 border-colorfaq"></h1>
                    </div>

                </div>
                <div className='mt-10 flex justify-evenly'>
                    <div className="grid grid-cols-1 gap-20 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 "  >

                        <div className=" shadow-lg reflect  hover:-translate-y-4 bg-white w-56 rounded-md border border-gray-200">
                            <div className="  bg-colorfaq ml-32 w-24 h-20 rounded-bl-full text-blue-300"></div>
                            <div className='flex justify-center'><img className="w-24 h-24 rounded-full border-4 shadow-xl border-white" src={team1} />
                            </div>
                            <div className="text-center">
                                <h6 className="text-xl font-semibold mb-2">Priyanka</h6>
                                <span className="text-blue-500 text-sm ">Web Developer</span>
                            </div>
                            {/* <h6 className="text-xl font-semibold mb-2 ml-20">Priyanka</h6>
                        <span className="text-gray-700 ml-16 -mt-5 text-sm">Web Developer</span> */}
                            <div className=" bg-gray-300 w-24 h-20 rounded-tr-full  text-gray-300">
                                <div className='flex ml-10 gap-2'>
                                    <FontAwesomeIcon icon={faFacebook} size='2x' className='mt-4 rounded-full' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faLinkedin} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faInstagram} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faXTwitter} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                </div>
                            </div>
                        </div>
                        <div className=" shadow-lg reflect  hover:-translate-y-4 bg-white w-56 rounded-md border border-gray-200">
                            <div className="  bg-colorfaq ml-32 w-24 h-20 rounded-bl-full text-blue-300"></div>
                            <div className='flex justify-center'><img className="w-24 h-24 rounded-full border-4 shadow-xl border-white" src={team4} />
                            </div>
                            <div className="text-center">
                                <h6 className="text-xl font-semibold mb-2">Deekshitha</h6>
                                <span className="text-blue-500 text-sm">Android Developer</span>
                            </div>
                            {/* <h6 className="text-xl font-semibold mb-2 ml-20">Priyanka</h6>
                        <span className="text-gray-700 ml-16 -mt-5 text-sm">Web Developer</span> */}
                            <div className=" bg-gray-300 w-24 h-20 rounded-tr-full  text-gray-300">
                                <div className='flex ml-10 gap-2'>
                                    <FontAwesomeIcon icon={faFacebook} size='2x' className='mt-4 rounded-full' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faLinkedin} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faInstagram} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faXTwitter} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                </div>
                            </div>
                        </div>
                        <div className=" shadow-lg reflect  hover:-translate-y-4 bg-white w-56 rounded-md border border-gray-200">
                            <div className="  bg-colorfaq ml-32 w-24 h-20 rounded-bl-full text-blue-300"></div>
                            <div className='flex justify-center'><img className="w-24 h-24 rounded-full border-4 shadow-xl border-white" src={team2} />
                            </div>
                            <div className="text-center">
                                <h6 className="text-xl font-semibold mb-2">Vamshi</h6>
                                <span className="text-blue-500 text-sm">Testing</span>
                            </div>
                            {/* <h6 className="text-xl font-semibold mb-2 ml-20">Priyanka</h6>
                        <span className="text-gray-700 ml-16 -mt-5 text-sm">Web Developer</span> */}
                            <div className=" bg-gray-300 w-24 h-20 rounded-tr-full  text-gray-300">
                                <div className='flex ml-10 gap-2'>
                                    <FontAwesomeIcon icon={faFacebook} size='2x' className='mt-4 rounded-full' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faLinkedin} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faInstagram} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faXTwitter} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                </div>
                            </div>
                        </div>
                        <div className=" shadow-lg reflect  hover:-translate-y-4 bg-white w-56 rounded-md border border-gray-200">
                            <div className="  bg-colorfaq ml-32 w-24 h-20 rounded-bl-full text-blue-300"></div>
                            <div className='flex justify-center'><img className="w-24 h-24 rounded-full border-4 shadow-xl border-white" src={team3} />
                            </div>
                            <div className="text-center">
                                <h6 className="text-xl font-semibold mb-2">Sainath</h6>
                                <span className="text-blue-500 text-sm">UI/UX Designer</span>
                            </div>
                            {/* <h6 className="text-xl font-semibold mb-2 ml-20">Priyanka</h6>
                        <span className="text-gray-700 ml-16 -mt-5 text-sm">Web Developer</span> */}
                            <div className=" bg-gray-300 w-24 h-20 rounded-tr-full  text-gray-300">
                                <div className='flex ml-10 gap-2'>
                                    <FontAwesomeIcon icon={faFacebook} size='2x' className='mt-4 rounded-full' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faLinkedin} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faInstagram} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                    <FontAwesomeIcon icon={faXTwitter} size='2x' className='mt-4 ' style={{ color: '#222222' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}