import React, { useState } from 'react'
import Map from '../Assets/map.png';
function Contact({contactRef}) {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can add your logic to submit the form data, like sending it to a backend server
        console.log(formData);
        // Reset form fields after submission
        setFormData({
            name: '',
            mobile: '',
            email: '',
            message: ''
        });
    };

    return (
        <div ref={contactRef}>
           <div className=' flex justify-center mt-10'>
        <div className=" w-4 h-4 border-t-4 border-l-4 border-customBlue"></div>
        <span className='text-2xl lg:text-4xl font-semibold text-customBlue '>Get In Touch</span>
        <div className="mt-6 w-4 h-4 border-b-4 border-r-4 border-customBlue "></div>
      </div>

            <div className='grid sm:grid-cols-2 grid-cols-1 shadow-transparent rounded-lg  md:p-16 p-4'>

                <div className='rounded-md shadow-lg'>
                    <a href="https://maps.app.goo.gl/S4HMMzZJAsmtcvPF8" target="_blank" rel="noopener noreferrer">
                        <img src={Map} alt="Description" className="w-full h-full" />
                    </a>

                </div>
                <div>

                    <div className="w-full mx-auto bg-white p-8 rounded-md shadow-md">

                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 font-medium  mb-2">Name</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-indigo-500" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="mobile" className="block text-gray-700 font-medium mb-2">Mobile Number</label>
                                <input type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-indigo-500" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email</label>
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-indigo-500" required />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="message" className="block text-gray-700 font-medium mb-2">Message</label>
                                <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500" rows="5" required></textarea>
                            </div>
                            <button type="submit" className="w-32 bg-blue border border-blue-950 text-blue-950 py-2 px-4 rounded-full hover:bg-blue-950 hover:text-white hover:ease-in-out transition duration-300">Submit</button>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Contact