import logo from './logo.svg';
import './App.css';
import React, { useRef } from 'react';
import NavBar from './Components/NavBar';
import Background from './Components/Background';
import Wave from './Components/wave';
import About from './Components/About';
import Services from './Components/Services';
import Numbers from './Components/Numbers';
import Projects from './Components/Projects';
import Communicate from './Components/Communicate';
import Testimonials from './Components/Testimonials';
import Clients from './Components/Clients';
import Faq from './Components/Faq';
import Team from './Components/Team';
import Contact from './Components/Contact';
import Footer1 from './Components/Footer1';
import Footer2 from './Components/Footer2';
import Tooltip from './Components/Tooltip';



function App() {

  const servicesRef=useRef(null);
  const aboutRef=useRef(null);
  const ProjectRef=useRef(null);
  const teamRef=useRef(null);
  const contactRef=useRef(null);
  const faqRef=useRef(null);

  return (
    <>
    <div>
      <NavBar servicesRef={servicesRef} aboutRef = { aboutRef }  ProjectRef={ProjectRef} teamRef={teamRef} contactRef={contactRef}  faqRef={faqRef} />
      <Background/>
      <Wave/>
     </div>
     <About aboutRef = { aboutRef } />
    <Services servicesRef = { servicesRef } />
    <Numbers/>
    <Projects ProjectRef={ProjectRef} />
    <Communicate/>
    <Testimonials/>
    <Clients/>
    <Team teamRef={teamRef}/>
    <Contact contactRef={contactRef}/>
    <Faq faqRef={faqRef}/>
    <Footer1/>
    <Tooltip/>
    <Footer2/>  
    </>
  );
}

export default App;
