import React from 'react'
import img3 from '../Assets/img3.jpg';
export default function Communicate() {
  return (
    <div className="bg-cover bg-fixed bg-no-repeat bg-center text-white py-10 px-5 md:py-20 md:px-10" style={{backgroundImage: `url(${img3})`}}>
      <span className='flex text-center justify-center text-3xl font-semibold'>Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit.</span>
      <span className='text-1xl flex justify-center'>Lorem Ipsum Dolor Sit Amet.</span>
      <section className='flex justify-center items-center mt-6  '>
        <button className=' border-2 rounded-3xl text-black hover:bg-blue-950 bg-white border-blue-950   px-8 py-0'>
          Contact Us
        </button>
      </section>
    </div>
  )
}
