import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testi1 from '../Assets/testi1.jpg';
import testi2 from '../Assets/testi2.jpg';
import testi3 from '../Assets/testi3.jpg';
import testi4 from '../Assets/testi4.jpg';
import testi5 from '../Assets/testi5.jpg';
const Testimonial = ({ name, role, content, imgSrc }) => {
  return (
    <div className='flex justify-center items-center'>
      <div className="  bg-white w-64 h-4/5">
        <div className="relative bg-no-repeat flex  justify-center">
          <img src={imgSrc} className="w-full h-72 object-cover " alt={`${name} avatar`} />
        </div>
        <div className=" ">
          <h5 className=" lg:mt-2 mb-2  text-lg font-bold text-black text-center">{name}</h5>
          <h6 className=" text-sm font-medium text-black text-center">{role}</h6>
          <p className="text-sm text-black justify-center  p-3 ">{content}</p>
        </div>
       
      </div>
    </div>
  );
};
const Testimonials = () => {
  const testimonials = [
    {
      name: "Vamshi",
      role: "Actor",
      content: "As an actor in this production,I can't express enough what a blessing it has been to work with Chakra Infotainment understand my vision and  goals for the project.",
      imgSrc: testi1
    },
    {
      name: "Deekshitha",
      role: "Client",
      content: "Working with Chakra Group was a refreshing experience.Their team of skilled professionals took the time understand my vision and goals for the project.",
      imgSrc: testi2
    },
    {
      name: "Sai",
      role: "Clientele",
      content: "The attention to detain in the design of the doll is truly remarkable.From its delicate features to the intricately stiched clothing,every aspects reflects the care and dedication ",
      imgSrc: testi3
    },
    {
      name: "Anjali",
      role: "Designer",
      content: "The attention to detain in the design of the doll is truly remarkable.From its delicate features to the intricately stiched clothing,every aspects reflects the care and dedication ",
      imgSrc: testi4
    },
    {
      name: "Priyanka Mathangi",
      role: "Tester",
      content: "The attention to detain in the design of the doll is truly remarkable.From its delicate features to the intricately stiched clothing,every aspects reflects the care and dedication ",
      imgSrc: testi5
    },
    // Add more testimonials here
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots:true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bg-backcolor">
    <div className="py-10">
      <div className=' flex justify-center  '>
       <div className=" w-4 h-4  border-t-4 border-l-4 border-blue-900"></div>
      <span className=' text-4xl font-semibold text-blue-900 '>Testimonials</span>
      <div className="mt-6 w-4 h-4 border-b-4 border-r-4 border-blue-900 "></div>
      </div>
      <span className='flex justify-center md:text-xl mt-4 mb-4'>See What People Have To Say About Us</span>
      <div className="flex justify-center items-center  ">
        <div className="w-full h-full mb-10 " >
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <Testimonial {...testimonial} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Testimonials;

