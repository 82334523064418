import React from 'react';
import client from '../Assets/client.png';
export default function Clients() {
  return (
    <div>
      <div className=' flex justify-center mt-10'>
       <div className=" w-4 h-4 border-t-4 border-l-4 border-customBlue"></div>
      <span className=' text-4xl font-semibold text-customBlue '>Our Clients</span>
      <div className="mt-6 w-4 h-4 border-b-4 border-r-4 border-customBlue "></div>
      </div>
      <span className='flex justify-center mt-10 text-md md:text-xl' >We Don't Just Build Softwares, We Build Your Business.</span>
      <div className='flex grid-cols-4 justify-center mt-10 mb-10'>
        <div><img src={client} alt=''></img></div>
        <div><img src={client} alt=''></img></div>
        <div><img src={client} alt=''></img></div>
        <div><img src={client} alt=''></img></div>
      </div>
    </div>
  )
}
